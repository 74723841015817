<template>
    <div class="MediaApplication">
        <el-page-header @back="$router.go(-1)" content="媒体信息"></el-page-header>
        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/IndustryTrendsList' }">行业动态</el-breadcrumb-item>
                <el-breadcrumb-item>媒体信息</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="banner f-c-c mb-40">
            <span class="name">媒体信息</span>
        </div>
        <div v-if="MediumStateInfo.mediumState == '30'" class="mt-20 mb-35 f-cl-c">
            <img class="mb-20" src="@/assets/Community/IndustryTrends/wait.png" alt="">
            <div class="fs-24 col-33">您提交的信息尚在审核中 请耐心等待！</div>
        </div>
        <div v-if="MediumStateInfo.mediumState == '21' || MediumStateInfo.mediumState == '31'" class="mt-20 mb-35 f-cl-c">
            <img class="mb-20" src="@/assets/Community/IndustryTrends/fail.png" alt="">
            <div class="fs-24 col-33 mb-20">您提交的申请不通过</div>
            <div class="fs-20 col-99">原因：{{ MediumInfo.auditNote }}</div>
        </div>
        <div class="main mt-20">
            <div class="f-cl-c">
                <div class="f-w-b w-1010 fs-16 " style="padding: 0 132px;width: 1010px;">
                    <div class="w--50 mb-30">
                        <div class="col-99">媒体名称：</div>
                        <div class="mt-10 col-33">{{ MediumInfo.mediumName }}</div>
                    </div>
                    <div class="w--50 mb-30">
                        <div class="col-99">联系人：</div>
                        <div class="mt-10 col-33">{{ MediumInfo.contact }}</div>
                    </div>
                    <div class="w--50 mb-30">
                        <div class="col-99">联系电话：</div>
                        <div class="mt-10 col-33">{{ MediumInfo.contactPhone }}</div>
                    </div>
                    <div class="w--50 mb-30">
                        <div class="col-99">所在城市：</div>
                        <div class="mt-10 col-33">{{ MediumInfo.city }}</div>
                    </div>
                    <div class="w--50 mb-30">
                        <div class="col-99">详细地址：</div>
                        <div class="mt-10 col-33">{{ MediumInfo.address }}</div>
                    </div>
                    <div class="w--100">
                        <div class="col-99">媒体简介：</div>
                        <div class="mt-10 col-33 lh-30">{{ MediumInfo.introduction }}</div>
                    </div>
                </div>
                <div v-if="MediumStateInfo.mediumState == '30'" class="mt-30 f-w"
                    style="padding: 0 132px;width: 1010px;">
                    <div class="w--50">
                        <div class="col-99">主页图片：</div>
                        <img class="mt-10 borderRa-15" style="width: 260px;height: 200px;" :src="MediumInfo.homeImg"
                            alt="">
                    </div>
                    <div class="w--50">
                        <div class="col-99">证书图片：</div>
                        <img class="mt-10 borderRa-15" style="width: 260px;height: 200px;"
                            :src="MediumInfo.certificateImg" alt="">
                    </div>
                </div>
                <div v-if="MediumStateInfo.mediumState == '10'" class="f-c-c Btn1 mt-60" @click="toMediaApplication">
                    修改信息</div>
                <!--  -->
                <div v-if="MediumStateInfo.mediumState == '30' || MediumStateInfo.mediumState == '31'" class="f-c-c mt-60">
                    <div class="f-c-c Btn2 mr-40" @click="toMediaApplication">修改信息</div>
                    <div class="f-c-c Btn1" @click="revokeMediumAudit()">取消修改</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MediaInfo',

    data() {
        return {
            MediumStateInfo: {},  // 媒体信息
            MediumInfo: {},
        }
    },
    // findForumMedium
    created() {
        this.MediumStateInfo = this.$getStorage('MediumStateInfo');
        let { mediumState } = this.MediumStateInfo;
        if (mediumState == '10') {
            this.findForumMedium();
        }else if(mediumState == '30' || mediumState == '31'){
           this.findMediumAudit(); 
        }
        
    },
    methods: {
        // 媒体详细信息
        findForumMedium() {
            let that = this;
            that.$http.findForumMedium({}).then(res => {
                if (res.code == 200) {
                    that.MediumInfo = res.data;
                }
            })
        },
        findMediumAudit() {
            let that = this;
            that.$http.findMediumAudit({}).then(res => {
                if (res.code == 200) {
                    that.MediumInfo = res.data;
                }
            })
        },
        toMediaApplication() {
            this.$router.push({
                name: 'MediaApplication',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        MediumInfo: this.MediumInfo
                    }))
                }
            });
        },
        revokeMediumAudit() {
            let that = this,
            mediumAuditId = that.MediumInfo.mediumAuditId;
            that.$confirm('确定取消修改？', '提示').then(() => {
                that.$http.revokeMediumAudit({
                    mediumAuditId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$router.go(-1);
                    }
                })
            }).catch(() => { })
        }
    },
}

</script>
<style lang='less' scoped>
.MediaApplication {
    .breadcrumb {
        padding: 10px 0;
        border-bottom: 1px solid #FBFBFB;
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
        color: #666666 !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
        color: #0363FA !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .banner {
        background: url('../../../assets/Community/IndustryTrends/bannerMediaAuthentication.png');
        height: 100px;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 40px;

        .name {
            font-size: 36px;
        }
    }

    .main {
        .formTitle {
            width: 100%;
            margin-left: 60px;
            margin-bottom: 22px;
            border-left: 4px solid #0363FA;
            padding-left: 10px;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
        }

        .Btn1 {
            width: 160px;
            height: 50px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            color: #FFF;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
        }

        .Btn2 {
            width: 160px;
            height: 50px;
            border-radius: 10px;
            background: #EFF4FA;
            color: #0363FA;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
        }
    }
}
</style>